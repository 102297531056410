import cn from 'classnames';
import { m } from 'framer-motion';
import { sources } from 'next/dist/compiled/webpack/webpack';
import { zoomVariants } from '@utils/motions';
import { isVideoUrl } from '@utils/urls';
import { MEDIUM_WIDTH } from '@constants';
import { VideoSource } from '@ts/shopify-storefront-api';
import styles from './InteractiveCard.module.scss';

type InteractiveCardMediaProps = {
	children?: React.ReactNode;
	background?: string;
	videoUrl?: string;
	mediaClassName?: string;
	backgroundMobile?: string;
	sourcesVideo?: VideoSource[];
};

export const InteractiveCardMedia = ({
	children,
	background,
	videoUrl,
	mediaClassName,
	backgroundMobile,
	sourcesVideo,
}: InteractiveCardMediaProps) => {
	if (children) {
		return (
			<m.div variants={zoomVariants} style={{ height: 'inherit' }}>
				{children}
			</m.div>
		);
	}

	if (isVideoUrl(background) || sourcesVideo?.length > 0 || videoUrl) {
		return (
			<video
				playsInline
				autoPlay
				muted
				loop
				preload='auto'
				style={{ width: '100%', height: '100%', objectFit: 'cover' }}
				poster={background}
			>
				<source src={sourcesVideo?.[0]?.url || videoUrl || background} type='video/mp4' />
				Your browser does not support the video tag.
			</video>
		);
	}

	return (
		background && (
			<m.picture className={cn(mediaClassName, styles.bgImage)} variants={zoomVariants}>
				<source srcSet={background} media={`(min-width: ${MEDIUM_WIDTH}px)`} />
				<m.img
					src={backgroundMobile || background}
					alt='background'
					width='100%'
					height='100%'
					style={{ objectFit: 'cover' }}
				/>
			</m.picture>
		)
	);
};
